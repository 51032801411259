body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-default {
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(14, 21, 106) 100%); /* Custom background color */
}

/* font */
@import url("https://fonts.googleapis.com/css?family=Inter:var(--single-line-body-base-font-weight)");
      @font-face {
        font-family: "Arial-Regular";
        src: url("https://anima-uploads.s3.amazonaws.com/projects/66eaa9f8e5d9b24120c2de64/fonts/satoshi-regular.ttf")
          format("truetype");
      }
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
      * {
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
      }
      html,
      body {
        margin: 0px;
        height: 100%;
      }
      /* a blue color as a generic focus style */
      button:focus-visible {
        outline: 2px solid #4a90e2 !important;
        outline: -webkit-focus-ring-color auto 5px !important;
      }
      a {
        text-decoration: none;
      }