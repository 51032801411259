
.th-content {
    display: flex;
    align-items: center;
    gap: 8px;
}

.sort-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 2px 6px;
    font-size: 14px;
    color: #666;
    border-radius: 4px;
}

.sort-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.sort-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(65, 66, 136, 0.2);
}