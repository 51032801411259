.side-panel {
  background: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.side-panel table {
  width: 100%;
  border-collapse: collapse;
}

.side-panel th {
  text-align: left;
  padding: 5px 25px 5px;
  width: 50%;
}

.side-panel td {
  text-align: right;
  padding: 5px 25px 5px;
  width: 50%;
}

/* Desktop styles */
@media (min-width: 993px) {
  .side-panel {
    width: 300px;
    height: 100vh;
    flex-shrink: 0;
    order: 1;
  }
  .tab-header {
    display: none !important;
  }

  .tab-content {
    display: flex;
    flex-direction: column;
  }

  .tab-pane {
    display: block !important;
    height: auto;
    max-height: 50%;
    overflow-y: auto;
  }

  .tab-pane:first-child {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

/* Mobile styles */
@media (max-width: 992px) {
  .side-panel {
    width: 100%;
    height: 50vh;
    order: 2;
    position: static;
    margin-top: 2rem;
  }
}

.tab-header {
  display: flex;
  border-bottom: 1px solid #ddd;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 12px 12px 0 0;
}

.tab {
  flex: 1;
  padding: 20px 12px 12px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  transition: all 0.2s;
}

.tab.active {
  color: #2f2f64;
  border-bottom: 2px solid #2f2f64;
}

.tab:hover {
  background-color: #f0f0f0;
}

.tab-content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  overflow-y: auto;
}

.tab-pane {
  display: none;
  height: 100%;
  align-content: center;
  overflow-y: auto;
}

.tab-pane.active {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.notes-list {
  text-align: left;
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
}

.note-input-container {
  position: sticky;
  bottom: 0;
  background: #f9f9f9;
  padding: 15px 0;
  border-top: 1px solid #ddd;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.note-input {
  width: 70%;
  min-height: 60px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
  resize: vertical;
}

.add-note-button {
  width: 70%;
  padding: 10px;
  background: #414288;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.add-note-button:hover {
  background: #0046cc;
}

/* popup message */
.note-message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  text-align: center;
  animation: fadeIn 0.3s ease-in;
}

.note-message.success {
  background-color: #e6ffe6;
  color: #006600;
  border: 1px solid #99ff99;
}

.note-message.error {
  background-color: #ffe6e6;
  color: #cc0000;
  border: 1px solid #ff9999;
}

.add-note-button.disabled {
  background: #cccccc;
  cursor: not-allowed;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.note-item {
  width: 80%;
  background: white;
  border-radius: 8px;
  padding: 12px 16px;
  margin: 0 auto 12px auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  border: 1px solid #eee;
  position: relative;
}

.note-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.note-meta {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  font-size: 12px;
  color: #666;
  width: calc(100% - 32px);
}

.note-meta strong {
  color: #2f2f64;
  margin-right: 4px;
}

.note-text {
  color: #333;
  line-height: 1.5;
  font-size: 14px;
  word-break: break-word;
}

/* Add visual separator between notes */
.note-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

/* Style scrollbar for better visibility */
.notes-list::-webkit-scrollbar {
  width: 8px;
}

.notes-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.notes-list::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.notes-list::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* edit and delete */
.note-action-btn {
  padding: 2px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.note-action-btn.edit {
  background-color: #4CAF50;
  color: white;
}

.note-action-btn.delete {
  background-color: #f44336;
  color: white;
}

.note-action-btn.save {
  background-color: #2196F3;
  color: white;
}

.note-action-btn.cancel {
  background-color: #9e9e9e;
  color: white;
}

.note-edit-input {
  width: 100%;
  min-height: 60px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 8px;
  resize: vertical;
}

.note-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.menu-container {
  position: absolute;
  top: 12px;
  right: 16px;
}

.three-dot-menu {
  background: none;
  border: none;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  padding: 0 8px;
  transition: color 0.2s;
}

.three-dot-menu:hover {
  color: #333;
}

.menu-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 120px;
}

.menu-item {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background-color 0.2s;
}

.menu-item:hover {
  background-color: #f5f5f5;
}

.menu-item:first-child {
  border-radius: 4px 4px 0 0;
}

.menu-item:last-child {
  border-radius: 0 0 4px 4px;
}