.dashboard-toggle {
    display: flex;
    justify-content: center;
    /* Keep pagination centered */
    align-items: center;
    /* Align items vertically */
    margin-top: 15px;
    margin-bottom: -15px;
    position: relative;
    /* Enable positioning for the button */
}

.dashboard-download {
    background-color: #414288;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    margin-bottom: 0.5rem;
}

.dashboard-download:hover {
    background-color: #0046cc;
}

.Dashboard {
    margin-bottom: 2rem;
    font-size: 35px;
}

.MapViewText {
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: center;
    font-size: 30px;
}

.Greeting {
    font-family: Montserrat, Arial;
    font-size: 20px;
}

.GreetingParagraph {
    font-family: Montserrat, Arial;
    font-size: 16px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    line-height: 1.5;
    padding: 0 10px;
    /* Padding for better readability */
    max-width: 80%;
    /* Constrain width */
    margin-left: auto;
    margin-right: auto;
    /* Center-align the text horizontally */
    color: #333;
    /* Ensure readability */
}

.DashboardInstructions {
    font-family: Montserrat, Arial;
}

.flex-row td {
    flex: 1;
    padding: 4px 8px;
    /* Reduced padding to decrease row height */
    border: 1px solid #ddd;
}

.location-pin {
    width: 20px;
    /* Reduced icon size */
    height: 20px;
    margin-right: 4px;
    font-size: 15px;
}

.location-pin:hover {
    cursor: pointer;
}

.react-paginate {
    display: inline-flex;
    justify-content: center;
    /* Ensure pagination icons are centered */
    align-items: center;
    gap: 0.5rem;
    width: auto;
    margin-top: 10px;
    overflow: hidden;
    padding: 0;
    font-weight: bold;
}

.react-paginate li {
    display: inline;
    list-style: none;
}

.react-paginate a {
    text-decoration: none;
    color: #414288;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-paginate a:hover {
    background-color: #f0f0f0;
}

.react-paginate .selected a {
    background-color: #414288;
    color: white;
    border-color: #414288;
}

.container {
    font-family: Montserrat, Arial;
    margin-top: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    width: 80vw;
    padding: 5px 10px;
    font-size: 0.9rem;
}

.status {
    background-color: #f8f9fa;
    color: black;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.status:hover {
    background-color: #e9ecef;
    border-color: #bbb;
}

.status:focus {
    outline: none;
    box-shadow: 0 0 4px #0078d4;
}

.status option[value="Not Started"] {
    background: #ffcccc;
    color: #b30000;
}

.status option[value="In Progress"] {
    background: #ffe680;
    color: #996600;
}

.status option[value="Completed"] {
    background: #ccffcc;
    color: #006600;
}

.status option {
    background: #ffffff;
    color: #333;
}

.data-table {
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0.5rem;
}

.dataView {
    width: 100%;
    margin: 10px auto;
    overflow-x: auto;
}

.dataView table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    overflow: hidden;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
    table-layout: auto;
}

.dataView th,
.dataView td {
    padding: 8px;
    word-wrap: break-word;
    vertical-align: middle;
    /* Ensures that the elements don't overlap when the page resizes to smaller sizes*/
}

.dataView th:nth-child(1),
.dataView td:nth-child(1) {
    width: 5%;
}

.dataView th:nth-child(6),
.dataView td:nth-child(6) {
    width: 10%;
}

.dataView th:nth-child(4),
.dataView td:nth-child(4) {
    width: 10%;
}

.dataView th:nth-child(5),
.dataView td:nth-child(5) {
    width: 10%;
}

.dataView th:nth-child(3),
.dataView td:nth-child(3) {
    width: 10%;
}

.dataView th:nth-child(2),
.dataView td:nth-child(2) {
    width: 10%;
}

.dataView th:nth-child(7),
.dataView td:nth-child(7) {
    width: 5%;
}

.status {
    width: 100%;
    height: 32px;
    box-sizing: border-box;
}

.location_pin {
    width: 30px;
    object-fit: contain;
}

.confirmation-message {
    width: 40%;
    background-color: #414288;
    color: white;
    padding: 10px;
    text-align: center;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    border-radius: 4px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1.5s ease-in-out;
}

.fade-in {
    opacity: 1;
    pointer-events: all;
}

.confirmation-message.fade-out {
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s ease-in-out;
}

@media (max-width: 768px) {

    .dashboard-toggle {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        justify-content: left;
    }

    .dashboard-toggle button {
        position: relative;
        padding: 6px 12px;
        font-size: 16px;
        margin-bottom: 1rem;
    }

    .dashboard-download {
        position: relative;
        right: 0;
    }

    .dataView th,
    .dataView td {
        padding: 6px;
        font-size: 14px;
    }

    .dataView {
        font-size: 20px;
    }

    .MapView {
        font-size: 20px;
    }

    .dataView table {
        font-size: 11px;
    }

    .container {
        width: 90%;
    }

    .react-paginate {
        gap: 0.25rem;
        font-size: 12px;
    }

    .dashboard-toggle {
        margin-bottom: -1rem;
    }
}

@media (max-width: 992px) {

    .dataView table {
        font-size: 12px;
    }

    .dataView th,
    .dataView td {
        padding: 5px;
    }

    .container {
        width: 100%;
    }


}

@media (max-width: 480px) {

    .Dashboard-Instructions {
        margin-bottom: 32vw;
    }

    .dashboard-toggle {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        justify-content: left;
    }

    .dashboard-toggle button {
        position: relative;
        padding: 6px 12px;
        font-size: 12px;
    }

    .dashboard-download {
        position: relative;
        right: 0;
        margin-top: -20px;
    }

    .container {
        width: calc(100% + 2rem);
        /*margin-top: 9rem;*/
    }

    .dataView {
        width: 100%;
    }

    .dataView th,
    .dataView td {
        padding: 2px;
        font-size: 12px;
    }

    .notes-toggle {
        padding: none;
        font-size: 9px;
        font-weight: 100;
        width: 2px;
    }

    .react-paginate {
        position: relative;
        transform: translateY(-20px);
    }
}

@media (max-width: 360px) {
    .Dashboard-Instructions {
        margin-bottom: 40vw;
    }

    .dashboard-toggle {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        justify-content: left;
    }

    .dataView th,
    .dataView td {
        padding: 0.2px;
        height: 10%;
        font-size: 10px;
        min-width: none;
    }

    .dataView {
        width: 100%;
    }

    .notes-toggle {
        padding: none;
        font-size: 11px !important;
        min-width: none;
    }
}

.notes-toggle {
    padding: 8px 16px;
    padding-top: 4px;
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: 100%;
    height: 32px;
    white-space: pre;
}

.notes-toggle:hover {
    background-color: #414288;
    color: white;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.notes-toggle:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}