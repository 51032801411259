.login {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  width: calc(100% + 2rem);
  max-width: calc(100% + 2rem);
  height: 80vh;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 0;
  overflow-x: hidden !important;
  background: linear-gradient(180deg, rgb(25, 16, 105) 0, rgba(0, 0, 0, 1) 65%);
  display: flex;
  flex-direction: column;
}

.login-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80vh;
  z-index: -1;
  opacity: 0.15;
  object-fit: cover;
  pointer-events: none;
}

.login-content {
  position: relative;
  z-index: 1;
  margin: auto;
  text-align: center;
  color: white;
}

video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.login-box {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  color: #333;
  width: 100%;
  max-width: 500px;
  margin: 2rem auto;
  padding: 3rem;
  border-radius: 12px;
  text-align: center;
}

.login-box h2 {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.login-box p {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.field-label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
  font-weight: 500;
  font-size: 1.1rem;
  text-align: left;
}

.login-box input {
  width: 100%;
  padding: 0.9rem 1rem;
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  margin-bottom: 1.2rem;
}

.login-box input:focus {
  outline: none;
  border-color: #999;
}

.login-box button {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #414288;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 0.9rem 1.2rem;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.login-box button:hover:not(:disabled) {
  background-color: #2f2c70;
  transform: scale(1.02);
}

.login-box button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
}

.icon {
  width: 22px;
  height: 22px;
}

.spinner {
  width: 22px;
  height: 22px;
  animation: rotate 0.5s linear infinite;
}

.spinner-circle {
  r: 8;
  cy: 12;
  cx: 12;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.bubble {
  width: 100%;
  padding: 1rem 1.2rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  box-sizing: border-box;
  text-align: center;
  line-height: 1.4;
}

.bubble-success {
  background-color: #22c55e;
  color: #fff;
}

.bubble-error {
  background-color: #dc2626;
  color: #fff;
}

.register-text {
  margin-top: 2rem;
  font-size: 1rem;
  color: #555;
}

.register-text a {
  color: #414288;
  text-decoration: none;
}

.register-text a:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .login-box {
    margin: 1rem;
    padding: 2rem;
    max-width: 90%;
  }

  .login-box h2 {
    font-size: 1.3rem;
  }

  .login-box p,
  .field-label,
  .login-box input,
  .login-box button,
  .bubble {
    font-size: 1rem;
  }

  .icon,
  .spinner {
    width: 20px;
    height: 20px;
  }

  .register-text {
    font-size: 0.95rem;
  }
}
