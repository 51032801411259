.App-logo {
  max-width: 100%;
  height: auto;
  max-height: 60px;
}

.navbar-toggler {
  border: none;
  font-size: 1.25rem;
}

.navbar-nav .nav-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  margin: 0 0.5rem;
  margin-right: 0.5rem;
}

.navbar-nav .nav-link:hover {
  text-decoration: underline;
}

.navbar-collapse {
  transition: height 0.3s ease-in-out, 1 0.3s ease-in-out;
  overflow: visible !important;
}

.navbar-nav {
  width: 100%;
  justify-content: flex-end;
}

.navbar {
  font-family: Montserrat, Arial;
  padding: 0;
  margin: 0;
}

.navbar-brand,
.navbar-nav,
.navbar-toggler {
  display: flex;
  align-items: center;
}

/* responsive css */
@media (max-width: 330px) {
  .App-logo {
    max-height: 10vw;
  }

  .navbar-toggler {
    font-size: 6vw;
  }
}