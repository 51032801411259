.header-auth {
  align-items: center;
  position: relative;
}

.header-auth .header-auth-button {
  margin-top: -1.00px;
  white-space: nowrap;
  width: fit-content;
  font-family: "Montserrat", Arial !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  text-align: center !important;
  background-color: #122071;
}

.header-auth.logged-out {
  display: flex;
  gap: 12px;
  padding: 8px 12px 8px 30px;
}

.header-auth.logged-in {
  border-radius: 8px;
  display: inline-flex;
  gap: 8px;
  padding: 8px 12px 8px 12px;
}

.header-auth-button:hover {
  background-color: #ffffff !important;
  color: #122071;
  border-color: #122071;
}

.my-account-icon-container{
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove any box shadow */
  background-color: transparent !important; /* Ensure background is transparent */
  color: inherit !important; /* Inherit text color */
}

.my-account-icon-container:hover,
.my-account-icon-container:focus,
.my-account-icon-container:active {
  background-color: transparent !important; /* Prevent background color change */
  color: inherit !important; /* Prevent text color change */
  box-shadow: none !important; /* Prevent box shadow */
}

.my-account-icon-container::after {
  display: none !important; /* Hide the default dropdown arrow */
}